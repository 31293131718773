import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../reducers";
import {NewsActions} from "../actions";
import { selectRecentNewsDetails, selectNews, selectTeacherMessages } from "../selectors/user.selectors";

@Injectable({
    providedIn: 'root',
})
export class NewsFacade {
    readonly newsList$ = this.store.select(selectNews);

    readonly recentNewsDetails$ = this.store.select(selectRecentNewsDetails);

    readonly teacherMessages$ = this.store.select(selectTeacherMessages);


    constructor(
        private readonly store: Store<AppState>
    ) {
    }

    public initNews(): void {
        this.store.dispatch(NewsActions.initNews());
    }

    public loadRecentNews(): void {
        this.store.dispatch(NewsActions.loadRecentNews());
    }

}
